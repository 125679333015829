<template>
    <div class="page">
        <el-form class="query-form" :inline="true" size="small" ref="inputForm" :model="inputForm" label-width="100px">
            <el-form-item label="参数名称" prop="configName">
                <el-input v-model.trim="inputForm.configName" clearable maxlength="50"
                          placeholder="请输入参数名称(限50字)"></el-input>
            </el-form-item>
            <el-form-item label="参数键名" prop="configKey">
                <el-input v-model.trim="inputForm.configKey" clearable maxlength="50"
                          placeholder="请输入参数键名(限50字)"></el-input>
            </el-form-item>
            <el-form-item label="系统内置" prop="configType">
                <el-select v-model="inputForm.configType"
                           placeholder="请选择系统内置"
                           style="width: 100%"
                           clearable>
                    <el-option
                        v-for="item in configTypeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间" prop="createTime">
                <el-date-picker
                    style="width: 100%"
                    v-model="inputForm.createTime"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button size="small" type="primary" @click="getDataList(1,1)" icon="el-icon-search">查询</el-button>
                <el-button size="small" @click="resetting()" icon='el-icon-refresh-right'>重置</el-button>
            </el-form-item>
            <el-form-item class="f_r">
                <el-button type="primary" size="small" v-show="hasPermissionButton('sys:parameter:parameter:add')"
                           icon='el-icon-plus' @click="addData(0)">新增
                </el-button>
                <el-button type="danger" size="small" icon="el-icon-delete" v-show="hasPermissionButton('sys:parameter:parameter:delete')" @click="deleteDate()">批量删除
                </el-button>
                <el-button type="danger" size="small" icon="el-icon-brush" v-show="hasPermissionButton('sys:parameter:parameter:cleanUp')" @click="cleanUp()">清理缓存
                </el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <el-table
                    :data="dataList"
                    size="small"
                    v-loading="loading"
                    ref="multipleTable"
                    height="calc(100vh - 348px)"
                    class="table"
                    :row-key="'id'"
                    @selection-change="selectionChangeHandle"
            >
                <el-table-column :reserve-selection="true" type="selection" width="50"/>
                <el-table-column prop="configId" label="参数主键" show-overflow-tooltip></el-table-column>
                <el-table-column prop="configName" label="参数名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="configKey" label="参数键名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="configValue" label="参数键值" show-overflow-tooltip></el-table-column>
                <el-table-column prop="configType" label="系统内置" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.configType == 'Y' ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createTime" sortable label="创建时间" width="150px">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="120">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text"
                                   @click="addData(2,scope.row)"
                                   v-show="hasPermissionButton('sys:parameter:parameter:edit')">
                            修改
                        </el-button>
                        <el-button size="mini" type="text"
                                   @click="deleteDate(scope.row)"
                                   v-show="hasPermissionButton('sys:parameter:parameter:delete')">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="current"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="size"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
        </div>

        <add-parameter ref="addParameter" @refresh="getDataList()"></add-parameter>
    </div>
</template>

<script>
import AddParameter from "@/views/modules/sys/parameter/addParameter.vue";

export default {
    name: "parameter",
    components: {AddParameter},
    data(){
        return{
            inputForm:{
                configName:'',
                configKey:'',
                configType:'',
                createTime:[],
                startDateTime:'',
                endDateTime:'',
            },
            configTypeList:[
                {
                    value:'Y',
                    label:'是',
                },
                {
                    value:'N',
                    label:'否',
                },
            ],

            dataListSelect: [],
            loading: false,
            dataList: [],

            pageNo2:0,
            current: 1,
            size: 10,
            total: 0,
        }
    },
    mounted() {
        this.getDataList()
    },

    methods:{
        getDataList(type, dividePage) {
            if (type == 1) {
                this.current = 1
            }
            if (dividePage) {
                this.$refs.multipleTable.clearSelection()
            }
            this.loading = true
            if (this.inputForm.createTime && this.inputForm.createTime.length == 2){
                this.inputForm.startDateTime = this.inputForm.createTime[0]
                this.inputForm.endDateTime = this.inputForm.createTime[1]
            }else {
                this.inputForm.startDateTime = ''
                this.inputForm.endDateTime = ''
            }
            let searData = JSON.parse(JSON.stringify(this.inputForm))
            this.$delete(searData,'createTime')
            this.$axios(this.api.auth.configList, {
                ...searData,
                current: this.current,
                size: this.size,
            }, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total)
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    if (this.dataList.length == 0 && this.current != 1){
                        this.current --;
                        this.getDataList()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },

        //num 0新增 2修改
        addData(num,row){
            this.$refs.addParameter.init(num,row)
        },

        //删除
        deleteDate(row){
            if(!row && !this.dataListSelect.length) {
                this.$message.warning('请至少选择一条数据')
                return
            }
            let configIds
            if (row){
                configIds = [row.configId]
            }else {
                configIds = this.dataListSelect.map(item=>{
                    return item.configId
                })
            }
            this.$confirm(`您是否确认删除该数据?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios(this.api.auth.systemConfig + '/' + configIds , {}, 'delete').then((res) => {
                    if (res.status) {
                        this.$message.success('删除成功')
                        this.getDataList('',1)
                    } else {
                        this.$message.error(res.msg);
                    }
                })
            })

        },

        //清理缓存
        cleanUp(){
            this.$axios(this.api.auth.configClearCache, {}, 'delete').then((res) => {
                if (res.status) {
                    this.$message.success('清理缓存成功')
                    this.getDataList('',1)
                } else {
                    this.$message.error(res.msg);
                }
            })
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', '');
        },

    },
}
</script>

<style scoped>
.bg-white{
    overflow-y: auto;
}
</style>
